<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="goods_title" label="商品名称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="goods_id" label="商品编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="up_level" :formatter="levelFormat" label="直升等级" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" show-overflow-tooltip>
        <template #header>
          <y_choose_goods @change="chooseNew">
            <el-button>选择商品</el-button>
          </y_choose_goods>
        </template>
        <template v-slot="s">
          <el-button @click="remove(s.row)" type="danger">移除</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
    <el-dialog title="创建升级商品" :visible.sync="editBox" @close="editBoxClose">
      <div>
        <el-form label-width="70px">
          <el-form-item label="商品">
            {{ choose_goods.id }}:{{ choose_goods.title }}
          </el-form-item>
          <el-form-item label="直升等级">
            <el-radio-group v-model="editForm.up_level">
              <el-radio :label="1">乡镇</el-radio>
              <el-radio :label="2">区县</el-radio>
              <el-radio :label="3">市</el-radio>
              <el-radio :label="4">省</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editBox = false">取 消</el-button>
        <el-button type="primary" @click="editSubmit">提交</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>

import y_choose_goods from "@/components/shop/y_choose_goods";

export default {
  components: {y_choose_goods},
  name: "manage",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      editBox: false,
      editForm: {
        goods_id: 0,
        up_level: 0,
      },
      choose_goods: {}
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    remove({id}){
      this.$u.api.shop.plugin.areaAgent.upGoodsRemove({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    levelFormat(e) {
      let str = "";
      switch (parseInt(e.up_level)) {
        case 1:
          str = "乡镇";
          break;
        case 2:
          str = "区县";
          break;
        case 3:
          str = "市";
          break;
        case 4:
          str = "省";
          break;
      }
      return str;
    },
    editBoxClose() {
      this.choose_goods = {};
      this.editForm = {
        goods_id: 0,
        up_level: 0,
      }
    },
    chooseNew(e) {
      this.choose_goods = e;
      this.editForm.goods_id = e.id;
      this.editBox = true;
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    editSubmit() {
      this.$u.api.shop.plugin.areaAgent.upGoodsSet(this.editForm).then(() => {
        this.$message.success("操作成功");
        this.editBox = false;
        this.load();
      })
    },
    load() {
      this.$u.api.shop.plugin.areaAgent.upGoodsSearch({page: this.page}).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>